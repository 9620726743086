import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

import Card from "../../../components/Card";
import TextField from "../../../components/TextField";
import { bookingStatusColors } from "../../../helpers/booking-status-colors";
import { bookingStatusTranslations } from "../../../helpers/booking-status-translations";
import paymentMethodTranslation from "../../../helpers/payment-method-translation";
import { useNotifications } from "../../../components/Notification";
import ChangeDateTimeButton from "../../../components/Buttons/ChangeDateTimeButton";
import itemOrderStatusTranslations from "../../../helpers/item-order-status-translations";
import { ActivityJson, Booking, PriceTypeJson } from "../../../types";

export default function ViewBooking() {
  const { id: reservationId } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery(GET_BOOKING, {
    variables: { id: reservationId },
  });

  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(GET_PRODUCT_ORDERS, {
    skip:
      !data ||
      !data.reservation ||
      !data.reservation.booking ||
      !data.reservation.booking.id,
    variables: { id: data?.reservation.booking.id },
  });

  const {
    loading: paidItemLoading,
    error: paidItemError,
    data: paidItemData,
  } = useQuery(GET_BOOKING_PAID_ITEMS, {
    skip:
      !data ||
      !data.reservation ||
      !data.reservation.booking ||
      !data.reservation.booking.id,
    variables: { id: data?.reservation.booking.id },
  });

  const [sending, setSending] = useState<boolean>(false);
  const [disableBtnSendMail, setDisableBtnSendMail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const { showNotification } = useNotifications();
  const [editBooking] = useMutation(EDIT_BOOKING_MUTATION);
  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION);

  useEffect(() => {
    setEmail(data?.reservation.booking.email);
    setPhoneNumber(data?.reservation.booking.phoneNumber);
    if (data?.reservation.booking.status == "CONFIRMED") {
      setDisableBtnSendMail(false);
    } else {
      setDisableBtnSendMail(true);
    }
  }, [data]);

  if (loading || productLoading || paidItemLoading) return <>loading...</>;
  if (error || productError || paidItemError)
    return (
      <>
        Error!{" "}
        {error?.message || productError?.message || paidItemError?.message}
      </>
    );

  const reservation: Reservation = data.reservation;
  const products: Products[] = productData.productOrders;
  const bookingPaidItems: BookingPaidItem[] = paidItemData.bookingPaidItems;
  const activity = getActivity(
    reservation.activityId,
    reservation.booking?.planSnapshot?.activities
  );

  function handleEditBooking() {
    editBooking({
      variables: { bookingId: reservation.booking.id, email, phoneNumber },
      refetchQueries: ["GetBookingQuery"],
    })
      .then(() => {
        showNotification({
          message: `予約の編集が完了しました`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `予約の編集に失敗しました! ${err}`,
          severity: "error",
        });
      });
  }

  async function handleSendEmail() {
    setSending(true);
    sendEmail({
      variables: { bookingId: reservation.booking.id },
    })
      .then(() => {
        setSending(false);
        showNotification({
          message: `予約確定メール再度送信されました。`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        setSending(false);
        showNotification({
          message: `予約確定メール送信に失敗しました! ${err}`,
          severity: "error",
        });
      });
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Button
          sx={{
            backgroundColor: "white",
            color: "#c8a063",
            borderColor: "#c8a063",
            "&:hover": {
              borderColor: "#c8a063",
            },
          }}
          variant="outlined"
          href="/bookings"
        >
          予約管理に戻る
        </Button>
        <Button
          sx={{
            color: "#ffffff",
            backgroundColor: "#c8a063",
            margin: "0.5em",
            "&:hover": {
              backgroundColor: "#a37939",
            },
          }}
          variant="contained"
          onClick={handleEditBooking}
        >
          保存
        </Button>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <Stack spacing={2}>
              <TextField
                textcolor={
                  bookingStatusColors[
                    reservation.booking.status as Exclude<
                      Booking["status"],
                      "CREATED"
                    >
                  ]
                }
                readOnly={true}
                label="予約ステータス"
                value={
                  bookingStatusTranslations[
                    reservation.booking.status as Exclude<
                      Booking["status"],
                      "CREATED"
                    >
                  ]
                }
              />
              {reservation.booking?.status === "CANCELLED" && (
                <TextField
                  readOnly
                  label="キャンセル理由欄"
                  value={reservation.booking.cancellationReason}
                />
              )}
              <TextField
                readOnly={true}
                label="予約ID"
                value={reservation.booking.id}
              />
              <TextField
                readOnly={true}
                label="店舗名"
                value={reservation.activity.venue.name}
              />
              <TextField
                readOnly={true}
                multiline={true}
                label="プラン名"
                value={
                  reservation.booking?.planSnapshot?.name ??
                  reservation.activity.plan.name
                }
              />
              <TextField
                readOnly={true}
                multiline={true}
                label="コース名"
                value={
                  reservation.booking?.planSnapshot?.activities
                    ? activity?.name
                    : reservation.activity?.json?.json?.name
                }
              />
              <TextField
                readOnly={true}
                multiline={true}
                label="お席タイプ/お席詳細"
                value={`${reservation.seatOption.title}\n${reservation.seatOption.description}`}
              />
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={4}>
                  <TextField
                    readOnly={true}
                    label="予約日時"
                    value={reservation.datetime.substr(0, 16)}
                  />
                </Grid>
                <Grid item xs={7.7}>
                  {(reservation.status === "NOT_YET_RESERVED" ||
                    reservation.status === "RESERVED") && (
                    <ChangeDateTimeButton
                      bookingId={reservation.booking.id}
                      reservationId={reservationId ?? ""}
                      currentReservationDateTime={reservation.datetime}
                    />
                  )}
                </Grid>
              </Grid>
              {reservation.items.map((i, index) => (
                <Stack key={index} direction="row" spacing={2}>
                  <TextField
                    readOnly={true}
                    label="ITEM_NAME"
                    value={
                      getPriceType(i.priceType.id, activity?.priceTypes)
                        ? getPriceType(i.priceType.id, activity?.priceTypes)
                            ?.name
                        : i.priceType.name
                    }
                  />
                  <TextField
                    readOnly={true}
                    label="ITEM_QUANTITY"
                    value={i.quantity}
                  />
                </Stack>
              ))}
              <TextField
                readOnly={true}
                label="料金明細"
                value={`${reservation.booking.paymentAmount} ${reservation.booking.paymentCurrency}`}
              />
              <TextField
                readOnly={true}
                label="お支払い方法"
                value={
                  paymentMethodTranslation[reservation.booking.payment.method]
                }
              />
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Card>
              <Stack spacing={2}>
                <TextField
                  textcolor={"red"}
                  readOnly={true}
                  multiline={true}
                  label="お客様からのコメント"
                  value={reservation.specialRequests}
                />
                {reservation.answers &&
                  reservation.answers.map((a, index) => (
                    <Stack key={index} spacing={2}>
                      <TextField
                        readOnly={true}
                        multiline={true}
                        label="質問"
                        value={a.question}
                      />
                      <TextField
                        textcolor={"red"}
                        readOnly={true}
                        label="回答"
                        value={a.answer}
                      />
                    </Stack>
                  ))}
              </Stack>
            </Card>
            <Card>
              <Stack spacing={2}>
                <TextField
                  readOnly={true}
                  label="ご予約名"
                  value={`${reservation.booking.familyName} ${reservation.booking.givenName} 様 (${reservation.booking.familyNameFurigana} ${reservation.booking.givenNameFurigana})`}
                />
                <TextField
                  label="お客様電話番号"
                  value={phoneNumber}
                  onChange={(e: any) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={8}>
                    <TextField
                      label="お客様メールアドレス"
                      value={email}
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Button
                    sx={{
                      color: "#ffffff",
                      backgroundColor: "#c8a063",
                      margin: "0.5em",
                      "&:hover": {
                        backgroundColor: "#a37939",
                      },
                    }}
                    disabled={
                      data?.reservation.booking.email !== email ||
                      loading ||
                      sending ||
                      disableBtnSendMail
                      // data?.reservation.booking.status !== 'CONFIRMED'
                    }
                    variant="contained"
                    onClick={handleSendEmail}
                  >
                    {sending === false ? "予約確定メール再送" : "送信中"}
                  </Button>
                </Grid>
              </Stack>
            </Card>
            {products &&
              products.map((product, index: number) => {
                return (
                  <Card key={product.id + index}>
                    <Stack spacing={2}>
                      <TextField
                        readOnly={true}
                        label="お祝いアイテム名"
                        value={
                          product?.product?.template?.overrideDisplayName ??
                          product?.product?.template?.displayName
                        }
                      />
                      <TextField
                        readOnly={true}
                        label="種類"
                        value={
                          product?.product?.overrideName ??
                          product?.product?.name
                        }
                      />
                      <TextField
                        readOnly={true}
                        label="お祝いアイテム注文ステータス"
                        value={itemOrderStatusTranslations[product.status]}
                      />
                      <TextField
                        readOnly={true}
                        label="お祝いアイテムを渡すタイミング"
                        value={
                          product?.product?.template?.planTemplates?.[0]
                            ?.whenToGive || ""
                        }
                      />
                      <TextField
                        readOnly={true}
                        label="お祝いアイテムの渡し方"
                        value={
                          product?.product?.template?.planTemplates?.[0]
                            ?.howToGive || ""
                        }
                      />
                      <Stack spacing={2} direction="row">
                        <TextField
                          readOnly={true}
                          label="金額"
                          value={
                            (product.price ?? product?.product?.sellingPrice) +
                            " JPY"
                          }
                        />
                        <TextField
                          readOnly={true}
                          label="数量"
                          value={product.quantity}
                        />
                      </Stack>
                      {product?.product?.template?.templateImages?.map(
                        (image, idx: number) => {
                          return (
                            <Box
                              key={"ItemImage_" + index + "_" + idx}
                              component="img"
                              sx={{
                                height: 200,
                                width: 350,
                                maxHeight: { xs: 200, md: 150 },
                                maxWidth: { xs: 350, md: 250 },
                              }}
                              alt="お祝いアイテムのイメージ"
                              src={image.imageUrl}
                            />
                          );
                        }
                      )}
                    </Stack>
                  </Card>
                );
              })}
            {bookingPaidItems && bookingPaidItems.length > 0 && (
              <Card>
                <Stack spacing={2}>
                  {bookingPaidItems.map((paidItem, index: number) => {
                    return (
                      <div style={{ display: "flex", gap: "16px" }} key={index}>
                        <TextField
                          readOnly={true}
                          label="PAID_ITEM_NAME"
                          value={paidItem.planPaidItem.name}
                        />
                        <TextField
                          readOnly={true}
                          label="PAID_ITEM_QUANTITY"
                          value={paidItem.quantity}
                        />
                        <TextField
                          readOnly={true}
                          label="PAID_ITEM_PRICE"
                          value={paidItem.planPaidItem.price}
                        />
                      </div>
                    );
                  })}
                </Stack>
              </Card>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export function getActivity(
  id: string | undefined,
  activities: ActivityJson[] | undefined
) {
  const activity = activities?.find((a: ActivityJson) => a.id === id) ?? null;
  return activity;
}

export function getPriceType(
  id: string | undefined,
  priceTypes: PriceTypeJson[] | undefined
): PriceTypeJson | null {
  if (!id || !priceTypes) return null;
  const priceType = priceTypes.find((p) => p.id === id) ?? null;
  return priceType;
}

export function getUnitType(type: string) {
  return type === "PERSON" ? "名" : "セット";
}

const GET_BOOKING = gql`
  query GetBookingQuery($id: ID!) {
    reservation(id: $id) {
      status
      datetime
      specialRequests
      activityId
      activity {
        name
        venue {
          name
        }
        plan {
          name
        }
        json {
          json {
            name
          }
        }
      }
      seatOption {
        title
        description
      }
      booking {
        id
        status
        familyName
        givenName
        familyNameFurigana
        givenNameFurigana
        email
        phoneNumber
        cancellationReason
        paymentAmount
        paymentCurrency
        planSnapshot {
          activities {
            name
            id
          }
        }
        payment {
          method
        }
        planSnapshot {
          name
          activities {
            id
            name
            priceTypes {
              id
              name
              unitType
              amountInMinorUnits
              amount
            }
          }
        }
      }
      items {
        quantity
        priceType {
          id
          name
        }
      }
      answers {
        question
        answer
      }
    }
  }
`;

const GET_PRODUCT_ORDERS = gql`
  query GetProductOrderByBooking($id: ID!) {
    productOrders(id: $id) {
      id
      quantity
      productId
      status
      price
      product {
        name
        overrideName
        templateId
        sellingPrice
        template {
          displayName
          overrideDisplayName
          templateImages {
            imageUrl
          }
          planTemplates {
            id
            whenToGive
            howToGive
          }
        }
      }
    }
  }
`;

const GET_BOOKING_PAID_ITEMS = gql`
  query GetBookingPaidItems($id: ID!) {
    bookingPaidItems(id: $id) {
      quantity
      planPaidItem {
        name
        price
      }
    }
  }
`;

const EDIT_BOOKING_MUTATION = gql`
  mutation EditBookingMutation(
    $bookingId: ID!
    $email: String!
    $phoneNumber: String!
  ) {
    editBooking(
      input: { bookingId: $bookingId, email: $email, phoneNumber: $phoneNumber }
    ) {
      success
    }
  }
`;

const SEND_EMAIL_MUTATION = gql`
  mutation SendEmailMutation($bookingId: ID!) {
    sendEmail(input: { bookingId: $bookingId }) {
      success
    }
  }
`;

interface Reservation {
  status: "NOT_YET_RESERVED" | "RESERVED" | "REJECTED" | "CANCELLED";
  datetime: string;
  specialRequests: string;
  activityId?: string;
  activity: Activity;
  seatOption: SeatOption;
  booking: Booking;
  items: ReservationItem[];
  answers?: ReservationAnswer[];
}

export interface Activity {
  id?: string;
  name: string;
  venue: Venue;
  plan: Plan;
  json: {
    json: {
      name: string;
    };
  };
}

interface Venue {
  name: string;
}

interface Plan {
  name: string;
}

interface SeatOption {
  title: string;
  description: string;
}

interface ReservationItem {
  quantity: number;
  priceType: PriceType;
}

interface PriceType {
  id?: string;
  name: string;
}

interface ReservationAnswer {
  question: string;
  answer: string;
}

interface BookingPaidItem {
  quantity: number;
  planPaidItem: {
    name: string;
    price: number;
  };
}

interface Products {
  id: string;
  productId: string;
  quantity: number;
  status: ProductOrderStatus;
  price?: number;
  product: {
    name: string;
    overrideName: string;
    templateId: string;
    sellingPrice: number;
    template: {
      displayName: string;
      overrideDisplayName: string;
      templateImages: {
        imageUrl: string;
      }[];
      planTemplates: {
        id: string;
        howToGive: string;
        whenToGive: string;
      }[];
    };
  };
}

export enum ProductOrderStatus {
  CREATED = "CREATED",
  REQUESTED = "REQUESTED",
  CONFIRMED = "CONFIRMED",
  SHIPPED = "SHIPPED",
  FAILED = "FAILED",
}
